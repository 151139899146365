import getAliasByLocale from './getAliasByLocale';
import { Route } from 'vue-router';

export function getBaseDomain(hostname?: string) {
  const host = hostname || (typeof window !== 'undefined' && window?.document?.location?.hostname);

  if (!host) {
    return false;
  }

  return `.${host.split('.').slice(-2).join('.')}`;
}

type getRouteParamsResult<type> = { id: type | undefined; parent: string | undefined; slug: string | undefined };
function getRouteParams({
  id,
  parent,
  slug,
  desiredIdType,
}: {
  id?: string | number;
  parent?: string;
  slug?: string;
  desiredIdType?: 'string';
}): getRouteParamsResult<string>;
function getRouteParams({
  id,
  parent,
  slug,
  desiredIdType,
}: {
  id?: string | number;
  parent?: string;
  slug?: string;
  desiredIdType?: 'number';
}): getRouteParamsResult<number>;
function getRouteParams({
  id,
  parent,
  slug,
  desiredIdType = 'string',
}: {
  id?: string | number;
  parent?: string;
  slug?: string;
  desiredIdType?: 'number' | 'string';
}) {
  const convertId = (id?: string | number) => {
    if (!id) {
      return;
    }

    if (desiredIdType === 'number') {
      return Number(id);
    }

    if (desiredIdType === 'string') {
      return String(id);
    }
  };

  const isIdValid = /^\d+$/.test(String(id));
  const newId = isIdValid ? convertId(id) : undefined;
  // Default id and slug are undefined because we don't want API to use them when they are not needed
  // Slug is only used when no valid id is found
  const params: { id?: string | number; parent?: string; slug?: string } = { id: undefined, parent: undefined, slug: undefined };

  if (newId) {
    params.id = newId;
  }

  // Expect id to be slug
  // Triggered for urls that have only slug
  if (!isIdValid && !newId && !slug) {
    params.slug = id as string;
  }

  if (!isIdValid && !newId && slug) {
    params.slug = slug;
  }

  if (parent) {
    params.parent = parent;
  }

  return params;
}

type FixUrlParamsArguments = { route: Route; id?: string | number | null; parent?: string | null; slug?: string | null; locale: string; type: string };
function fixUrlParams({ route, id, parent, slug, locale, type }: FixUrlParamsArguments) {
  const { origin } = document.location;
  const name = getAliasByLocale(locale, type);

  const notValid = {
    id: isNaN(Number(route.params.id)) && id,
    parent: parent && (!route.params.parent || route.params.parent !== parent),
    slug: slug && (!route.params.slug || route.params.slug !== slug),
    path: name && route.path && name !== route.path.split('/')[1],
    page: route.query.page && Number(route.query.page) <= 0,
  };

  if (notValid.id || notValid.slug || notValid.path || notValid.page) {
    // Create a new href with correct params
    let href = `${origin}/${name}/${id}/${slug}`;

    // Add query to href and filter out negative page parameter
    if (Object.keys(route.query).length > 0) {
      const queryString = Object.keys(route.query)
        .filter((key) => !(notValid.page && key === 'page'))
        .map((key) => `${key}=${route.query[key]}`)
        .join('&');
      href = queryString ? href.concat(`?${queryString}`) : href;
    }

    // Add route hash to the end of path
    href = href.concat(route.hash);

    window.history.replaceState({}, document.title, href);
  }
}

export { getRouteParams, fixUrlParams };
